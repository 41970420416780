<template>
  <div>
    <h1>Holaa</h1>
  </div>
</template>

<script>
export default {
  name: "Logout"
}
</script>

<style scoped>

</style>